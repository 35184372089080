.blockquote {
	margin: 0;
	padding: 0;
	position: relative;
	padding: $padding-rythm-sm 0 0 $padding-rythm-sm;
	font-size: $fs-body-lg;
	&:before {
		content: url(../images/website/quote.svg);
		position: absolute;
		top: 0;
		left: 0;
		width: 35px;
		height: 26px;
	}
	&__body {
		position: relative;
		font-style: italic;
		z-index: 1;
	}
	&__citation {
		margin-top: 10px;
		&:before {
			content: "—";
			margin-right: 0.5em;
		}

		& > .rich-text {
			display: inline-block;
		}
	}
}

@include from($bp-small) {
	.blockquote {
		padding: $padding-rythm 0 0 $padding-rythm;
		font-size: $fs-subtitle;
		line-height: 30px;

		&:before {
			width: 70px;
			height: 55px;
		}
	}
}
