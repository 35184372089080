.index-search {
	--icon-width: 25px;
	position: relative;
	border-bottom: 1px solid white;
	width: 100%;
	margin: $padding-rythm 0;

	&__input {
		background: transparent;
		outline: 0;
		border: 0;
		appearance: none;
		width: 100%;
		font-size: var(--icon-width);
		color: $c-beige-middle;
		font-family: $heading-font;
		padding: 0px;
		padding-right: var(--icon-width);

		&::placeholder {
			color: white;
			opacity: 1;
		}
	}

	&__icon {
		position: absolute;
		right: 0;
		top: 0;
		appearance: none;
		outline: 0;
		border: 0;
		background: transparent;
		width: var(--icon-width);
		height: 100%;
		padding: 0;
		color: $c-beige-middle;

		& > svg {
			width: 100%;
			height: 100%;
		}
	}

	&__results {
		&.is-loading {
			min-height: 100vh;
			width: 100%;
			display: flex;
			justify-content: center;
			& > .loading-spinner {
				display: inline-block;
			}
			& > *:not(.loading-spinner) {
				display: none;
			}
		}
		@include from-md {
			> .l-site-content__slider-title:first-child {
				margin-top: 160px;
			}
		}
	}

	&__no-results {
		@include base-sans;
		& > a {
			display: inline;
			text-decoration: underline;
			font-weight: bold;
		}
	}

	@include from-md {
		--icon-width: 60px;
		margin: $padding-rythm * 2 0;
	}
	@include from-lg {
		--icon-width: 80px;
	}
}
