.pagination {
	display: flex;
	margin: 0 auto;
	justify-content: center;
	font-size: $fs-body-lg;
	font-family: $heading-font;
	margin-bottom: $padding-rythm;
	margin-top: $padding-rythm * 2;

	& > * {
		&:not(:first-child) {
			margin-left: 15px;
		}
		&.is-current {
			text-decoration: underline;
		}
	}

	&__next,
	&__prev {
		> svg {
			width: 12px;
			height: 12px;
		}
	}

	&__next {
		transform: rotateY(180deg);
	}
}
