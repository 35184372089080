.video-embed {
	position: relative;

	display: block;
	width: 100%;

	box-shadow: 10px 30px -10px rgba(0, 0, 0, 0.5);

	&:only-child {
		margin-bottom: 0;
	}

	&__cover {
		transition: opacity 500ms 300ms ease-in-out;
		position: relative;
		display: block;
		//Important because of IE
		text-decoration: none !important;

		width: 100%;
		padding-bottom: #{(1080 / 1920) * 100%};
		display: block;
		height: 0;
		overflow: hidden;

		&.has-video {
			opacity: 0;
		}

		& svg {
			position: absolute;
			top: 50%;
			left: 50%;

			width: 88px;
			height: 88px;

			text-decoration: none;

			transition: $transition-default;
			transform: translate(-50%, -50%) translateZ(0);

			z-index: 100;
		}
		&:hover {
			&:after {
				transform: translate(-50%, -50%) translateZ(0);
				opacity: 0.7;
			}
		}
	}

	&__cover-image,
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__cover-image {
		display: block;

		transition: $transition-default;
		object-fit: cover;

		transform: translate3d(0, 0, 0);
	}

	&__title {
		font-size: $fs-subtitle;
		font-family: $heading-font;
		margin-bottom: 10px;
	}

	iframe {
		z-index: $zi-layer-1;
	}
}

@include from($bp-medium) {
	.video-embed {
		&__cover {
			&:after {
				border-top: 40px solid transparent;
				border-bottom: 40px solid transparent;

				border-left: 80px solid white;
			}
		}
	}
}
