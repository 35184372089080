$footer-dark-text: #87817d;

.l-site-footer {
	background: $c-neut-grey-middle;
	padding: $padding-rythm-sm * 2 $padding-rythm-sm;
	&__logo {
		width: 80px;
		height: auto;
	}
	&__address {
		font-style: normal;
	}
	&__phone {
		font-style: normal;
	}
	&__subtitle {
		font-family: $heading-font;
		font-weight: bold;
		display: block;
		margin-top: 0.5em;
	}
	&__section {
		color: $footer-dark-text;
	}

	&__form-intro {
		margin-bottom: 1em;
	}

	&__ack {
		margin: $padding-rythm 0;
		line-height: 1.5;
		a {
			font-weight: 700;
			display: initial;
		}
	}

	&__menu {
		$menu: &;
		display: flex;
		align-items: flex-start;
		gap: $padding-rythm-sm * 1.5;
		&--primary {
			color: $c-beige-middle;
		}
		&--service-links {
			font-size: $fs-micro;

			#{$menu}-item {
				font-family: $body-font;
			}
		}

		&-item {
			@include base-menu-link;
		}
	}

	&__signup {
		transition: opacity 150ms ease-in-out;
		&-fields {
			display: block;
		}

		.form-input {
			&__field {
				border-color: $footer-dark-text;
				color: $footer-dark-text;
			}
		}
		.btn {
			display: block;
			width: 100%;
			padding: $padding-rythm-sm / 2;
			background-color: $footer-dark-text;
			white-space: nowrap;
			color: white;
			font-weight: 500;
		}
		&-error {
			display: block;
			margin-top: 1em;
		}

		&.is-loading {
			opacity: 0.5;

			& * {
				cursor: wait;
			}
		}
	}
}

@include to-sm {
	.l-site-footer {
		&__signup {
			&-fields {
				flex-direction: column;
				gap: 5px;
			}

			.btn {
				padding: 1em 0;
				justify-content: center;
			}
		}
		&__menu {
			flex-direction: column;
			gap: $padding-rythm-sm;
		}

		&__menu--service-links {
			margin-bottom: $padding-rythm * 3;
		}
	}
}

@include to-md {
	.l-site-footer {
		&__address {
			margin-top: $padding-rythm;
		}

		.social-bar {
			margin: $padding-rythm 0;
		}
		&__menu {
			margin-top: $padding-rythm;
		}
		&__uluru {
			display: flex;
			flex-direction: column-reverse;
			& > img {
				max-width: 140px;
				margin-top: 1rem;
			}
		}
	}
}

@include from-md {
	.l-site-footer {
		padding: $padding-rythm * 2 $site-gutter-lg $padding-rythm $site-gutter-lg;

		&__section {
			display: grid;
			grid-template-columns: 1fr 1fr;
			&-vertical {
				display: flex;
				flex-direction: column;
			}

			& + & {
				margin-top: $padding-rythm * 2;
			}
		}

		&__logo {
			width: 104px;
		}

		&__ack {
			margin-bottom: $padding-rythm-sm;
		}
		&__uluru {
			display: grid;
			grid-template-columns: auto 140px;
			gap: 1rem;
			margin-top: 1.5rem;
		}
		.social-bar {
			margin-top: $padding-rythm;
		}
		&__navigation {
			grid-area: menus;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: flex-end;
		}

		&__menu {
			flex-wrap: wrap;
			&-item {
				white-space: nowrap;
			}
		}
	}
}

@include from-max {
	.l-site-footer {
		padding: $padding-rythm * 2 0 $padding-rythm 0;

		&__inner {
			max-width: $max-width;
			margin: 0 auto;
		}
	}
}
