.l-page {
	padding: 0 $padding-rythm-sm;
	width: 100%;
	height: 100%;
	overflow-x: hidden;

	&__background {
		position: absolute;
		z-index: -2;
		width: 100%;
		height: 45vh;
		background-size: cover;
		top: 0;
		left: 0;
		opacity: 0.5;
		filter: grayscale(100%);
	}

	&__overlay {
		background: linear-gradient(
			to bottom,
			transparent 0%,
			#{$c-purp-grey-middle} 100%
		);
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 45vh;
		position: absolute;
		top: 0;
		left: 0;
		background-size: cover;
	}
}

@include from-md {
	.l-page {
		padding: $site-gutter-lg;
		padding-bottom: $padding-rythm * 4;

		&__overlay,
		&__background {
			top: $header-height-lg;
		}
	}
}
