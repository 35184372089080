.social-bar {
	list-style: none;
	padding: 0;
	margin: 0;
	&__item {
		display: inline-block;
		margin-right: 20px;
	}
	&__link {
		display: block;
		width: 20px;
		height: 20px;
		svg {
			color: $c-beige-dark;
			width: 100%;
			height: auto;
			display: block;
		}

		&:focus,
		&:hover {
			svg {
				color: $c-beige-light;
			}
		}
	}

	@include to-md {
		&__link {
			width: 20px;
			height: 20px;
		}
	}
}
