*,
*:before,
*:after {
	box-sizing: border-box;
}

body,
html {
	padding: 0;
	margin: 0;
}

a {
	color: inherit;
	display: block;
	text-decoration: none;
}

ul,
ol {
	margin: 0;
	padding: 0;
	display: block;
}

ul li {
	list-style: none;
}

img {
	width: 100%;
	height: auto;
	display: block;
}

p {
	margin: 0;
}

body {
	position: relative;

	&.menu-open {
		max-height: 100vh;
		overflow-y: hidden;
	}
}
