.book-card {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100%;
	position: relative;
	&__placeholder-title {
		position: absolute;
		font-family: $heading-font;
		color: $c-beige-middle;
		padding: 0 20px;
		top: 20px;
	}
	&__thumb {
		display: block;
		box-shadow: $box-shadow-dark;
		picture {
			width: 100%;
			height: auto;
		}
	}
}

@include to($bp-small) {
	.book-card {
		&__meta {
			display: none;
		}
	}
}
@include from($bp-small) {
	.book-card {
		$c: &;

		transition:
			z-index 300ms step-end,
			opacity 300ms ease-in-out,
			transform $base-anim-rythm ease-in-out;

		&__meta {
			position: absolute;
			top: -$padding-rythm;
			width: calc(300% + (2 * 10px) + #{$padding-rythm * 2});
			min-height: calc(100% + #{$padding-rythm * 2});
			padding: $padding-rythm;

			background: $c-neut-grey-dark;

			display: flex;
			flex-direction: column;

			transition: opacity 300ms ease-in-out;
			opacity: 0;
			pointer-events: none;
			transform: translateZ(0);
			&-title {
				@include base-heading;
				color: $c-beige-dark;
				font-size: $fs-subtitle;
				margin-bottom: $padding-rythm-sm - 5px;
			}
			&-author {
				color: $c-beige-middle;
				margin-bottom: $padding-rythm - 5px;
			}
			&-published,
			&-genres {
				color: $c-beige-light;
			}
			&-published {
				margin-bottom: $padding-rythm-sm - 5px;
			}
			&-genres {
				margin-bottom: $padding-rythm - 5px;
			}
			&-summary {
				color: $c-beige-dark;
			}
			&-ctas {
				margin-top: auto;
				align-self: flex-end;
			}

			&-close {
				position: absolute;
				background: none;
				padding: 0;
				border: 0 none;
				top: $padding-rythm / 2;
				right: $padding-rythm / 2;
				svg {
					width: 14px;
					height: 14px;
					transition: transform $base-anim-rythm ease-in-out;
					backface-visibility: hidden;
					margin-left: 10px;
					margin-top: -1px;
				}
			}
		}
		&__thumb {
			transition: transform 300ms ease-in-out;
			position: relative;
			z-index: 2;
		}

		&.is-left {
			#{$c}__meta {
				left: -$padding-rythm;
				padding-left: calc(100% + #{$padding-rythm * 2});
				&-ctas {
					align-self: flex-start;
				}
			}
		}
		&.is-right {
			#{$c}__meta {
				right: -$padding-rythm;
				padding-right: calc(100% + #{$padding-rythm * 2});
			}
		}

		&.is-active {
			z-index: 1;
			#{$c}__meta {
				pointer-events: all;
				opacity: 1;
			}
			#{$c}__thumb {
				transform: translateY(calc(-1 * var(--remaining-height)));
			}
			&:hover {
				transform: translateY(0);
			}
		}

		&:hover {
			transform: translateY(-4px);
		}
	}

	.swiper-container {
		transition: z-index 300ms step-end;
		position: relative;
		&.has-open-book {
			z-index: 2;
			.book-card:not(.is-active) {
				opacity: 0.5;
			}
			.swiper-button-prev,
			.swiper-button-next {
				opacity: 0;
				pointer-events: none;
			}
		}
	}
}
