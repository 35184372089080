.btn {
	$c: &;
	appearance: none;
	background: none;
	border: 0;
	outline: 0;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&--arrow {
		svg {
			transition: transform $base-anim-rythm ease-in-out;
			backface-visibility: hidden;
			margin-left: 10px;
			width: 5px;
			height: 11px;
			margin-top: -1px;
		}
	}

	&__text {
		font-family: $heading-font;
	}

	&__subtext {
		color: $c-beige-dark;
		font-family: $body-font;
		font-weight: normal;
		font-size: $fs-micro;
		margin-left: 4px;
		text-decoration-color: red;
	}
	&--solid {
		padding: $padding-rythm-sm;
		background: $c-beige-middle;

		.btn {
			&__text {
				font-weight: 500;
			}
			&__arrow {
				width: 14px;
				height: 14px;
			}
		}
	}
	&--white {
		background: white;
	}

	&--inline {
		display: inline-flex;
		width: 50%;
	}

	&:hover,
	&:focus,
	&:active {
		#{$c}__text {
			text-decoration: underline;
		}
		#{$c}__arrow {
			transform: translateX(4px);
		}
	}

	&--reverse {
		& > svg {
			margin-left: 0;
			margin-right: $padding-rythm-sm;
		}
	}
}

.btn-group {
	.btn + .btn {
		margin-top: $padding-rythm-sm;
	}
}

@include from-md {
	.btn {
		&__arrow {
			margin-left: $spacing-rythm-sm * 2;
		}
		&--mobile-only {
			display: none;
		}
	}
	.btn-group {
		.btn + .btn {
			display: inline-block;
			margin-top: 0;
			margin-left: $padding-rythm-sm;
		}
	}
}
