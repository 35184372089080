.index-tabs {
	$c: &;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	font-family: $heading-font;

	&__tab {
		background-color: $c-purp-grey-dark;
		transition: $transition-default;

		&.is-active {
			background-color: $c-neut-grey-dark;
			color: white;
		}
	}

	&__link {
		padding: $padding-rythm-sm;
		transition: $transition-default;

		&:hover {
			color: white;
		}
	}

	@include to-sm {
		font-size: $fs-micro;
	}
}
