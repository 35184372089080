.l-post {
	&__meta {
		font-style: italic;
		margin-bottom: $padding-rythm;
		& > span {
			display: inline-block;
			&:first-child {
				margin-right: 30px;
			}
		}
	}

	&__profile-link {
		margin-top: $padding-rythm-sm;
	}

	@include to-md() {
		&__aside {
			display: none;
		}
	}

	@include from-md() {
		&__profile-link {
			float: right;
		}
	}
}
