.l-home-fold {
	background: url(../images/website/background-768.jpg);
	background-size: cover;
	padding: $padding-rythm-sm * 5 $padding-rythm-sm $padding-rythm;
	display: flex;
	position: relative;
	align-items: center;

	margin-left: -$site-gutter;
	margin-right: -$site-gutter;

	&__logo {
		width: 52px;
		margin-top: 10px;
		position: absolute;
		top: $padding-rythm-sm;

		&.logo_main {
			left: $padding-rythm-sm + 20px;
		}
		&.logo_nsb {
			left: $padding-rythm-sm + 100px;
		}
		&.logo_press {
			left: $padding-rythm-sm + 200px;
		}
	}

	&__title {
		@include base-heading;
		line-height: 1.2;
		font-size: $fs-mega;
		color: $c-beige-dark;
	}
	&__subtitle {
		@include base-heading;
		color: $c-beige-dark;
	}
	&__title,
	&__subtitle,
	&__ctas {
		position: relative;
		z-index: 1;
	}
	&__ctas {
		margin-top: $padding-rythm * 1.5;
		.btn {
			color: white;
		}
	}
	&:after {
		content: "";
		width: 100%;
		height: 20%;
		position: absolute;
		bottom: 0;
		left: 0;
		background: linear-gradient(
			to top,
			rgba($c-purp-grey-middle, 1),
			rgba($c-purp-grey-middle, 0)
		);
	}
}

@include from-md {
	.l-home-fold {
		margin-left: -$site-gutter-lg;
		margin-right: -$site-gutter-lg;
		padding: $padding-rythm * 5 $padding-rythm-sm $padding-rythm * 3;
		flex-direction: column;
		justify-content: center;

		&__logo {
			display: none;
		}

		&__title {
			font-size: $fs-mega-lg;
			margin-bottom: $padding-rythm-sm / 2;
		}
		&__subtitle {
			font-size: $fs-subtitle;
		}
		&__ctas {
			.btn {
				display: inline-block;
				+ .btn {
					margin-left: $padding-rythm * 2;
				}
			}
		}
	}
}

@include from-max {
	.l-home-fold {
		padding-left: 0;
		padding-right: 0;
		&__inner {
			max-width: $max-width;
			width: 100%;
			margin: 0 auto;
		}
	}
}
