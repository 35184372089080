.award-year {
	margin: $padding-rythm * 2 0;

	&__item {
		background-color: $c-beige-middle;
		padding: 15px 18px;
		overflow: hidden;

		& + & {
			margin-top: $padding-rythm-sm;
		}

		&.closed {
			& .award-year {
				&__groups {
					max-height: 0px;

					& > * {
						opacity: 0;
						transition-delay: 0ms;
					}
				}

				&__title {
					& > svg {
						transform: rotate(-90deg);
					}
				}
			}
		}
	}

	&__group-title,
	&__title {
		@include base-heading;
		font-weight: bold;
	}

	&__title {
		display: flex;
		align-items: center;
		cursor: pointer;
		& > svg {
			margin-left: auto;
			width: 14px;
			height: 14px;
			transition: transform 150ms ease-in-out;
			transform: rotate(90deg);
		}
	}

	&__groups {
		transition: max-height 200ms ease-in-out;
		opacity: 1;

		& > * {
			transition: opacity 300ms ease-in-out;
			opacity: 1;
		}
	}

	&__group {
		&-title {
			font-size: $fs-body-lg;
			padding: 8px 0;
		}
	}

	&__text {
		& > p + p {
			margin-top: 3px;
		}
	}

	@include from-md {
		&__item {
			padding: 30px 35px;
		}

		&__group {
			&-title {
				padding: $padding-rythm-sm 0 3px 0;
			}
		}
	}
}
