$control-size: 1em;

.form-boolean {
	--control-active-colour: white;

	&--dark,
	&--light {
		--control-active-colour: $c-purp-grey-middle;

		& .form-boolean {
			&__label {
				& > a {
					display: inline;
					text-decoration: underline;
				}
			}
		}
	}

	&--dark {
		& .form-boolean {
			&__label {
				& > svg {
					& > path {
						stroke: black;
					}
				}
			}
		}
	}

	&--light {
		margin: $padding-rythm-sm 0;

		& .form-boolean {
			&__label {
				& > svg {
					& > path {
						stroke: white;
					}
				}
			}
		}
	}

	input {
		display: none;
	}
	&__label {
		@include base-sans;
		position: relative;
		padding-left: 1.7 * $control-size;
		cursor: pointer;
		display: block;
		line-height: 1;
		padding-top: 2px;

		&:before {
			content: "";
			width: $control-size;
			height: $control-size;
			position: absolute;
			left: 0;
			top: 1px;
		}

		& > svg {
			width: $control-size + 0.2em;
			height: $control-size + 0.2em;
			position: absolute;
			left: 1px;
			top: -1px;
		}

		& > svg {
			& > path {
				stroke-dasharray: 100;
				stroke-dashoffset: 100;
				fill: none;
				stroke: $c-beige-middle;
				stroke-width: 2px;
			}
		}

		&:before {
			border: 1px solid $c-beige-middle;
		}

		&:hover {
			&:before {
				border-color: var(--control-active-colour);
			}
		}
	}
	input[type="radio"] + &__label {
		&:before,
		&:after {
			border-radius: 100%;
		}
	}
	input:checked + &__label {
		color: var(--control-active-colour);
		&:before {
			border-color: var(--control-active-colour);
		}
		& > svg > path {
			transition: 1s linear;
			stroke-dashoffset: 0;
		}
	}
}

.form-boolean-group {
	&__label {
		@include base-sans;
		display: block;
		margin-bottom: 12px;
	}
	.form-boolean {
		+ .form-boolean {
			margin-top: $padding-rythm-sm;
		}
	}
}
