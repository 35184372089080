.captioned-media {
	&__asset {
		width: 100%;
		height: auto;
		display: block;
		box-shadow: $box-shadow-light;
	}
	&__caption {
		font-size: 14px;
		padding-top: $padding-rythm-sm;
	}

	&--half-width {
		max-width: 50%;
		margin: 0 auto 0 0;
	}
}
