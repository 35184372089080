.site-search {
	background-color: $c-neut-grey-dark;
	width: 100%;
	z-index: 3;
	opacity: 0;
	position: absolute;
	top: $header-height-lg;
	left: 0;
	padding: $padding-rythm-sm * 1.5 $site-gutter-lg;
	pointer-events: none;
	transition: all 300ms ease-in-out;

	&__input {
		color: $c-beige-middle;
		background: transparent;
		border: 0;
		border-bottom: 1px solid $c-beige-middle;
		width: 70%;
	}

	&__submit {
		color: $c-beige-middle;
		appearance: none;
		border: 0;
		outline: 0;
		background: transparent;
		margin-left: $padding-rythm-sm;
		bottom: -4px;
		position: relative;
		display: inline-block;
	}

	&.is-active {
		box-shadow: $box-shadow-dark;
		opacity: 1;
		pointer-events: all;
	}

	@include to-md() {
		padding: $padding-rythm-sm;
		position: fixed;
		top: auto;
		bottom: 58px;

		form {
			display: flex;
			justify-content: space-between;
			gap: $padding-rythm-sm;
		}

		&__input {
			width: auto;
			flex-grow: 1;
		}

		&__submit {
			margin-left: 0;
		}
	}
}
