.card {
	transition: transform $base-anim-rythm ease-in-out;
	display: block;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: $c-neut-grey-dark;
	box-shadow: $box-shadow-dark;

	&__image {
		picture {
			display: block;
			width: 100%;
			height: auto;
		}
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&__details {
		min-height: 165px;
		padding: $padding-rythm-sm;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__title {
		font-size: $fs-subtitle;
	}
	&__supertitle {
		font-style: italic;
		display: flex;
		justify-content: space-between;
		margin-bottom: $padding-rythm-sm;
	}
	&__subtitle {
		margin-bottom: $padding-rythm-sm;
	}
	&__summary {
		margin-bottom: $padding-rythm-sm;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 5;
		overflow: hidden;
	}
	&__cta {
		margin-left: auto;
	}

	&:hover {
		transform: translateY(-4px);
	}
}

@include from($bp-medium) {
	.card {
		&__title {
		}
	}
}
