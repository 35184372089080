.l-marketing-page {
	padding-top: $padding-rythm * 2;
	&__breadcrumbs {
		display: flex;
		justify-content: space-between;
	}

	& > .l-column-content {
		// Not great, probably a better way to organise this
		margin-top: $padding-rythm-sm;
	}

	&__read-more {
		margin-top: $padding-rythm-sm;
	}

	&__breadcrumb {
		@include base-sans;
	}

	@include from-max {
		max-width: $max-width;
		margin: 0 auto;
	}
}
