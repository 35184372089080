$thickness: 100;
$pageOffset: 1;

.book-model {
	perspective: 900px;
	transform: scale(0.8);
	display: flex;
	justify-content: center;

	&__skeleton {
		position: relative;
		transform-style: preserve-3d;
		transform: rotateY(-30deg);
		transition: transform 340ms ease-in-out;
		will-change: transform;

		&::before,
		&::after {
			content: "";
			border: 0 solid #e5e7eb;
			display: block;
			position: absolute;
		}

		&:before {
			position: absolute;
			content: "";
			right: 0;
			top: #{$pageOffset}px;
			width: #{$thickness}px;
			height: calc(100% - (2 * #{$pageOffset}px));
			$pagesWidth: $thickness - 2;
			transform: translateX(#{$pagesWidth / 2}px) rotateY(90deg);
			background: linear-gradient(
				90deg,
				#fff 0%,
				#f9f9f9 5%,
				#fff 10%,
				#f9f9f9 15%,
				#fff 20%,
				#f9f9f9 25%,
				#fff 30%,
				#f9f9f9 35%,
				#fff 40%,
				#f9f9f9 45%,
				#fff 50%,
				#f9f9f9 55%,
				#fff 60%,
				#f9f9f9 65%,
				#fff 70%,
				#f9f9f9 75%,
				#fff 80%,
				#f9f9f9 85%,
				#fff 90%,
				#f9f9f9 95%,
				#fff 100%
			);
		}

		&:after {
			background-color: #87817d;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: translateZ(#{($thickness / 2) * -1}px);
		}
	}

	&__cover,
	&__back {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__cover {
		transform: translateZ(#{$thickness / 2}px);
	}
}
