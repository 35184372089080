.resource-links {
	order: 3;
	margin-top: $padding-rythm;
	width: 100%;

	&__title {
		margin-bottom: 10px;
		font-weight: 500;
		font-size: $fs-subtitle;
	}
	&__list {
		& .btn {
			line-height: 1.4;
			& + .btn {
				margin-top: 10px;
			}
		}
	}

	&__text {
		margin-top: $padding-rythm-sm;
	}

	&__extra {
		&-logo {
			max-width: 70px;
			margin-bottom: $padding-rythm-sm;
		}
	}

	@include to-md {
		&__extra,
		&__title {
			margin-top: $padding-rythm;
		}
	}

	@include from-md {
		margin-top: 120px;

		&__columns {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: $padding-rythm;
		}

		&__text {
			margin-top: $padding-rythm;
		}

		&__extra {
			margin-top: $padding-rythm * 2;
		}
	}
}
