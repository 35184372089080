.l-collection-page {
	padding-top: $padding-rythm * 2;
	&__title {
		font-size: $fs-mega-lg;
		font-family: $heading-font;
		font-weight: normal;
		color: $c-beige-middle;
		line-height: 1.1;
		margin-bottom: 180px;
	}
	// FIXME convert to card-grid
	&__members {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: $padding-rythm * 2;
		@supports (display: grid) {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			gap: $padding-rythm 10px;
		}
	}

	&__book,
	&__collection {
		flex-basis: 15%; // 1/6 + some spaceing
		align-self: end;
	}

	&__collection {
		position: relative;

		&-text {
			position: absolute;
			top: $padding-rythm * 1.5;
			padding: 0 20px;
			display: flex;
			flex-direction: column;
			font-family: $heading-font;
			color: $c-beige-middle;
		}

		&-title {
			color: white;
			margin-top: $padding-rythm;
		}
	}
}
