body {
	font-size: $fs-body;
	font-family: $body-font;
	line-height: 1.4;
	background: $c-purp-grey-middle;
	color: $c-beige-middle;
	width: 100vw;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include base-heading;
}

.lede {
	& > .rich-text {
		font-size: $fs-subtitle;
		line-height: 30px;
	}
}

.richtext,
.rich-text {
	line-height: 24px;
	> * + * {
		margin-top: 0.4em;
	}
	> * + h1,
	> * + h2,
	> p + p {
		margin-top: 1em;
	}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}

	a:not([class]) {
		@include base-link;
		display: inline;
	}

	ol {
		padding-left: 1em;
	}

	ul {
		list-style: none;
		padding: 0;
		li {
			position: relative;
			padding-left: 0.7em;
			&:before {
				content: "•";
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

	table {
		tr {
			border-bottom: 1px solid;
			&:last-child {
				border-bottom: 0 none;
			}
		}
		th,
		td {
			vertical-align: top;
			text-align: left;
		}
		th {
			font-weight: normal;
			padding-bottom: $padding-rythm-sm / 2;
			padding-right: $padding-rythm-sm;
		}
		td {
			padding-top: $padding-rythm-sm / 4;
			padding-bottom: $padding-rythm-sm / 2;
			padding-right: $padding-rythm-sm;
		}
	}

	h5 {
		font-size: $fs-body;
		text-transform: uppercase;
	}

	.richtext-image {
		box-shadow: $box-shadow-light;

		&.left,
		&.right {
			margin-top: 0;
			max-width: 40%;
		}

		&.left {
			float: left;
			margin-right: 1em;
		}

		&.right {
			float: right;
			margin-left: 1em;
		}
	}
}

.responsive-object {
	position: relative;
	iframe,
	img {
		position: absolute;
		height: 100%;
		width: 100%;
	}
}

@include from($bp-medium) {
	.richtext,
	.rich-text {
		> * + * {
			margin-top: 1em;
		}
		*:first-child {
			margin-top: 0;
		}
		*:last-child {
			margin-bottom: 0;
		}
	}
}
