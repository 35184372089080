@include to($bp-medium) {
	.l-site-header {
		position: relative;
		height: 0;
		position: absolute;
		z-index: 100;
		&__logo {
			display: none;
			&-img {
				width: 100%;
				height: auto;
			}
		}

		&__menu-trigger {
			position: absolute;
			top: $padding-rythm-sm;
			right: $padding-rythm-sm;
			z-index: 2;
		}

		&__priority-navigation {
			position: fixed;
			bottom: 0px;
			left: 0px;
			height: 57px;
			width: 100%;
			z-index: 2;

			background: $c-neut-grey-middle;
			box-shadow: 0 -2px 4px rgba(black, 0.5);
			display: flex;
			&-item {
				padding: 10px 0;
				flex-basis: 25%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;
				flex-direction: column;
				svg {
					color: $c-beige-dark;
					display: block;
					height: 19px;
					width: 20px;
				}

				&.is-active {
					span,
					svg {
						color: $light-grey;
					}
				}
			}

			&-label {
				@include base-sans;
				font-size: 10px;
			}
		}

		&__navigation {
			transition: transform 300ms ease-in-out;
			width: 100vw;
			height: 100vh;
			position: fixed;
			display: none;
			top: 0;
			left: 0;
			transform: translateX(100%);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			z-index: 1;
			overflow: scroll;
			background-color: $c-purp-grey-dark;

			&-item {
				@include base-sans();
				padding: $padding-rythm-sm / 4;

				&:hover {
					color: white;
				}
			}
			&.is-active {
				transform: translateX(0);
			}
		}
		&__search-trigger {
			display: none;
		}
	}
}

@include from($bp-medium) {
	.l-site-header {
		padding: 0 $padding-rythm-sm;
		height: $header-height-lg;
		box-shadow: $box-shadow-dark;
		position: fixed;
		width: 100%;
		z-index: 3;
		background-color: $c-neut-grey-middle;

		&__inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
		}

		&__logo {
			width: 104px;
			padding: $padding-rythm-sm / 4;
		}

		&__priority-navigation {
			display: none;
		}

		&__navigation {
			position: static;
			transform: translateX(0);
			width: auto;
			height: auto;
			flex-direction: row;
			background: none;

			display: flex;
			&-item {
				@include base-menu-link;
				padding: 0;
			}
			&-item + &-item {
				margin-left: $padding-rythm-sm * 1.5;
			}
		}

		&__search-trigger {
			width: 20px;
			appearance: none;
			background: transparent;
			border: 0;
			outline: 0;
			padding: 0;
			margin-left: $padding-rythm-sm * 1.5;
			color: $c-beige-middle;
			&.is-active,
			&:hover {
				color: $light-grey;
			}
		}
	}
}

@include from($bp-large) {
	.l-site-header {
		padding: 0 $site-gutter-lg;
	}
}

@include from-max {
	.l-site-header {
		padding: 0;
		&__inner {
			max-width: $max-width;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
