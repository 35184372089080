.swiper-container {
	margin-left: -$site-gutter;
	margin-right: -$site-gutter;
	padding: 0 $site-gutter;
	width: 100vw;
	position: relative;
	display: none;

	&.swiper-initialized {
		display: block;
	}

	.swiper-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		transition-property: transform;
	}
	.swiper-button-prev,
	.swiper-button-next {
		transition: opacity $base-anim-rythm ease-in-out;
		z-index: 2;
		position: absolute;
		top: 0;
		height: 100%;
		width: $spacing-rythm-sm;
		border: 0 none;
		opacity: 1;

		& > svg {
			display: none;
		}
	}

	.swiper-button-disabled {
		opacity: 0;
	}
	.swiper-button-prev {
		left: 0;
		background: linear-gradient(
			to left,
			rgba($c-purp-grey-middle, 0),
			rgba($c-purp-grey-middle, 0.5)
		);
	}
	.swiper-button-next {
		right: 0;
		background: linear-gradient(
			to right,
			rgba($c-purp-grey-middle, 0),
			rgba($c-purp-grey-middle, 0.5)
		);
	}
}

.swiper-slide {
	flex-shrink: 0;
	width: 100%;
	height: auto;
	position: relative;
	transition: opacity $base-anim-rythm ease-in-out;
}

@include to($bp-small) {
	.swiper-slide {
		&.swiper-slide-prev,
		&.swiper-slide-next + .swiper-slide + .swiper-slide {
			opacity: 0.4;
		}
	}
}

@include from($bp-small) {
	.swiper-container {
		margin: 0 (-$site-gutter);
		padding: 0 $site-gutter;
		.swiper-button-prev,
		.swiper-button-next {
			width: $padding-rythm * 2;
			svg {
				color: $c-beige-light;
				display: block;
				height: 32px;
				width: 15px;
				position: absolute;
				bottom: $padding-rythm * 2;
			}
		}
		.swiper-button-next {
			svg {
				right: 25px;
			}
		}
		.swiper-button-prev {
			svg {
				left: 25px;
			}
		}
	}
	.swiper-slide {
		opacity: 0.4;

		&.swiper-slide-active,
		&.swiper-slide-active ~ .swiper-slide {
			opacity: 1;
		}

		&.swiper-slide-real-next,
		&.swiper-slide-real-next ~ .swiper-slide {
			opacity: 0.4;
		}
	}
}

@include from($bp-medium) {
	.swiper-container {
		margin: 0 (-$site-gutter-lg);
		padding: 0 $site-gutter-lg;
	}
}

@include from-max {
	.swiper-container {
		margin: 0 calc(-1 * ((100vw - #{$max-width}) / 2));
		padding: 0 calc((100vw - #{$max-width}) / 2);
	}
}
