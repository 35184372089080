/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
$bp-tiny: 420px;
$bp-small: 768px;
$bp-medium: 1024px;
$bp-large: 1280px;
$bp-very-large: 1440px;

/**
 * Font definitions
 */
$body-font:
	Georgia,
	Times,
	Times New Roman,
	serif;
$heading-font: "Gotham", sans-serif;

/**
 * Font Sizes
 */

// Desktopish
$fs-mega-lg: 86px; // 86px - Fold title
$fs-title-lg: 40px; // 40px - Marketing block title, article title,

// Mobileish
$fs-mega: 40px; // 40px - Fold title, Menu title, Search title,
$fs-title: 28px; // 28px - Marketing block title,
$fs-subtitle: 20px; // 20px - Search bar value, Filter view (open) title
$fs-body-lg: 18px; // Lede
$fs-body: 16px; // 14px - Fold subtitle, "Out now"/Book tour pip, book group supertitle/link, marketing block subtitle,    Site link (fold title), marketing tour dates, marketing quote
$fs-micro: 12px; // 12px - "Unsw Press" super title (.brand-pip), "You searched for"

// Custom
// 50px - Author search letters - large
// 30px - Author search letters

/**
 * Transitions
 */
$base-anim-rythm: 180ms;
$transition-default: all $base-anim-rythm ease-in-out;

/**
 * Padding
 * Usage: padding: $padding-rythm*2
 */
$padding-rythm: 40px;
$padding-rythm-sm: 20px;

$spacing-rythm: 50px;
$spacing-rythm-sm: 10px;

$site-gutter: $padding-rythm-sm;
$site-gutter-lg: $padding-rythm * 2;

/**
 * Raw Colors
 */
$dark-grey: #58595b;
$middle-grey: #808285; // #a7a9ac - put the similar colors you've replaced here
$light-grey: #efeff0; // #eaebec

$c-neut-grey-dark: #090808;
$c-neut-grey-middle: #1c191a;

$c-purp-grey-dark: #151214;
$c-purp-grey-middle: #221e20;

$c-beige-dark: #9c9187;
$c-beige-middle: #cdc5bd;
$c-beige-light: #eceae8;

/**
 * z-index stack - where possible use DOM order or small hoists (eg z-index: 1 vs z-index: $zi-layer-4)
 */
$zi-layer-1: 20;
$zi-layer-2: 40;
$zi-layer-3: 60;
$zi-layer-4: 80;
$zi-layer-nav: 200;

/**
 * Misc
 */
$max-width: ($bp-very-large + 1px);
$header-height-lg: 120px;
$header-height-sm: 70px;
// On dark bg
$box-shadow-dark: 5px 7px 20px rgba(9, 8, 8, 0.8);
// On light bg
$box-shadow-light: 5px 7px 20px -5px rgba(98, 95, 92, 0.6);
