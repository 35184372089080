.author-card {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100%;
	background: $c-neut-grey-dark;
	box-shadow: $box-shadow-dark;

	&__name {
		color: $c-beige-middle;
		font-family: $heading-font;
		font-size: $fs-body-lg;
		padding: $padding-rythm-sm;
	}

	&__supertext {
		padding: $padding-rythm-sm;
		font-style: italic;
		color: $c-beige-dark;
	}

	&__cta {
		padding: $padding-rythm-sm;
		align-self: flex-end;
		color: $c-beige-middle;
	}
}
