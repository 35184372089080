.location-picker {
	margin-top: $padding-rythm;

	& .choices {
		border: 0;
		width: auto;
		display: inline-block;
		&__inner {
			border: 0;
			padding: 0 !important;
			background-color: transparent;
			width: auto;
			min-height: auto;
		}

		&__list {
			// right: 0;

			&.is-active {
				width: 250px;
			}

			&--single {
				text-decoration: underline;
				padding-right: 0px;

				& .choices {
					&__item {
						padding-right: 0;
					}
				}
			}
		}

		&__item {
			&--selectable {
				padding-right: 10px;
				line-height: 1;
			}
		}

		&::after {
			content: none;
		}
	}
}
