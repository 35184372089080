.l-site-content {
	& > * + * {
		margin-top: $padding-rythm;
	}
	&__slider-title {
		@include base-sans;
		margin-top: $padding-rythm-sm * 3;
		margin-bottom: $padding-rythm-sm;
		justify-content: start;

		& + .l-card-grid,
		& + .swiper-container {
			margin-top: 0;
		}
	}
	&__breadcrumb {
		@include base-sans;
		color: white;
		padding-top: $padding-rythm-sm;

		& > svg {
			margin-right: 8px;
			margin-left: 0;
			transition: transform $base-anim-rythm ease-in-out;
			backface-visibility: hidden;
			width: 5px;
			height: 11px;
			margin-top: -1px;
		}
	}
}

@include to-md {
	.l-site-content {
		margin-bottom: $padding-rythm;
		padding-top: 0.1px; //Needed for margin collapsing

		.marketing-card,
		.marketing-card + * {
			margin-top: $padding-rythm * 2;
			&:first-child {
				margin-top: 0;
			}
		}
	}
}

@include from-md {
	.l-site-content {
		position: relative;

		&__breadcrumb {
			display: none;
		}

		.marketing-card,
		&__slider-title {
			margin-top: $padding-rythm * 4;
			&:first-child {
				margin-top: 0;
			}
		}

		&__slider-title {
			font-size: $fs-subtitle;
			margin-bottom: $padding-rythm-sm;
		}
	}
}

@include from-max {
	.l-site-content {
		max-width: $max-width;
		margin: 0 auto;
	}
}
