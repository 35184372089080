.l-streamfield {
	& > * + * {
		margin-top: $padding-rythm;
	}

	& > .lede + .rich-text {
		margin-top: $padding-rythm-sm;
	}

	@include from-md {
		& > * + * {
			margin-top: $padding-rythm * 2;
		}
	}
}
