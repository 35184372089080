.marketing-card {
	background: $c-neut-grey-dark;
	display: flex;
	flex-direction: column-reverse;
	color: $c-beige-dark;
	&__copy {
		display: flex;
		flex-direction: column;
		padding: $padding-rythm $padding-rythm-sm;
	}
	&__supertitle {
		@include base-heading;
		font-size: $fs-subtitle;
		margin-bottom: $padding-rythm-sm / 2;
	}
	&__title {
		@include base-heading;
		color: white;
		font-size: $fs-title;
		margin-bottom: $padding-rythm-sm / 2;
	}
	&__cta {
		margin-left: auto;
		margin-top: $padding-rythm-sm;
		color: $c-beige-middle;
	}
	&__supertitle,
	&__subtitle {
		margin-bottom: $padding-rythm-sm;
	}

	&__pip {
		color: $c-beige-middle;
		margin-bottom: $padding-rythm-sm / 2;
	}

	&__image {
		position: relative;
		margin: auto;
		& img {
			width: 100%;
			height: 100%;
		}
	}

	&__gradient {
		position: absolute;
		background: linear-gradient(
			90deg,
			rgba(9, 8, 8, 1) 0%,
			rgba(9, 8, 8, 0) 100%
		);
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
	}

	&__quote {
		// Same structure, bit different layout
		font-size: $fs-body;
		line-height: 20px;
		&:before {
			content: url(../images/website/quote-grey.svg);
		}
	}
}

@include to-md {
	.marketing-card {
		&__gradient {
			display: none;
		}
	}
}

@include from-md {
	.marketing-card {
		display: grid;
		grid-template-areas: "copy image";
		grid-template-columns: 1fr 350px;
		&__copy {
			padding: $padding-rythm;
			padding-right: 200px;
			position: relative;
		}
		&__title {
			font-size: $fs-title-lg;
		}
		&__title,
		&__pip {
			margin-bottom: $padding-rythm-sm;
		}
		&__cta {
			position: absolute;
			right: $padding-rythm;
			bottom: $padding-rythm;
		}
		&__flexible-content {
			margin-top: auto;
		}
	}
}
