.l-book-page {
	&__title {
		font-weight: bold;
		font-size: $fs-title;
	}

	&__subtitle {
		font-size: 21px;
		font-weight: normal;
	}

	&__author {
		font-family: $heading-font;
		margin: 15px 0 5px 0;
	}

	&__metadata {
		& > span {
			white-space: nowrap;
		}
	}

	&__reviews {
		order: 3;
		flex-grow: 2;
		flex-basis: 100%;
		margin-top: $padding-rythm;
	}

	@include from-md {
		&__title {
			font-size: $fs-title-lg;
		}
		&__subtitle {
			font-size: 21px;
		}

		&__reviews {
			margin-top: $padding-rythm * 2;
			display: grid;
			gap: 10px;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-areas:
				"r1 r2 r3"
				"r4 r5 r6"
				"r7 r8 r9";
			@for $i from 1 through 9 {
				& > .review-card:nth-child(#{$i}) {
					grid-area: r#{$i};
				}
			}
		}
	}
}
