.l-author-listing {
	column-count: 3;
	color: $c-beige-dark;

	&__section {
		break-inside: avoid;
		margin-bottom: $padding-rythm;
	}

	&__letter {
		font-size: $fs-title-lg;
	}

	&__list {
		font-family: $heading-font;

		& li {
			padding-top: $padding-rythm-sm;
		}

		& a {
			&:hover {
				text-decoration: underline;
			}
		}
	}

	@include from-md {
		column-count: 5;
		margin-top: $padding-rythm * 2;
	}
}
