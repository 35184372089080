@mixin block-base-sm() {
	background: white;
	padding: $padding-rythm-sm;
}

@mixin block-base() {
	background: white;
	padding: $padding-rythm;
}

@mixin base-heading() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.357;
	font-family: $heading-font;
	margin: 0;
}

@mixin base-sans() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: $heading-font;
}

@mixin base-menu-link() {
	@include base-sans;
	position: relative;
	overflow: hidden;
	&:after {
		transition:
			transform 150ms ease-in,
			opacity 150ms ease-in;
		content: "";
		position: absolute;
		width: 100%;
		height: 1px;
		background: currentColor;
		right: 100%;
		bottom: 0;
		opacity: 0;
	}
	&.is-active,
	&:hover {
		&:after {
			opacity: 1;
			transform: translateX(100%);
		}
	}
}

@mixin base-link() {
	transition: all 150ms ease-in;
	text-decoration: underline;
	text-underline-offset: 4px;
	display: inline-block;
	position: relative;
	&:hover,
	&:focus,
	&:active {
		color: $c-beige-dark;
		text-decoration-color: $c-beige-dark;
	}
}
