.review-card {
	background-color: $c-beige-middle;
	z-index: 2;
	padding: $padding-rythm;
	position: relative;
	box-shadow: $box-shadow-light;

	&:before {
		content: url(../images/website/quote-light.svg);
		color: white;
		position: absolute;
		top: 39px;
		left: 35px;
		width: 55px;
		z-index: 1;
	}

	&__date {
		display: block;
		width: 100%;
		text-align: right;
	}
	&__text {
		z-index: 2;
		margin-top: $padding-rythm-sm;
		position: relative;
		line-height: 21px;
	}
	&__attribution {
		display: block;
		margin-top: $padding-rythm-sm;
	}

	@include to-md {
		& + & {
			margin-top: $padding-rythm-sm;
		}
	}
}
