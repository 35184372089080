.reminder-form {
	margin-top: $padding-rythm-sm;
	order: 3;
	&__title {
		font-weight: 500;
		font-size: $fs-subtitle;
		margin-bottom: 12px;
	}
	&__form {
		margin-top: $padding-rythm-sm;
		& > * + * {
			margin-top: $padding-rythm-sm;
		}

		& input[type="text"] {
			width: 300px;
		}
	}
}
