.sponsor-grid {
	&__grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: $padding-rythm-sm;
	}

	&__title {
		@include base-heading;
		font-weight: bold;
		margin-bottom: $padding-rythm-sm;
	}

	& + .btn {
		margin-top: $padding-rythm * 2;
	}

	&--mobile-only {
		margin-top: $padding-rythm;
	}

	@include from-md {
		&--mobile-only {
			display: none;
		}
	}
}
