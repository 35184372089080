.index-form {
	&__filters {
		background-color: $c-neut-grey-dark;
		width: 100%;
		padding: $padding-rythm-sm 0;

		.form-boolean-group {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			row-gap: $padding-rythm-sm;

			&__label {
				flex-basis: 100%;
				padding: 0 $padding-rythm-sm;
			}

			.form-boolean {
				margin: 0;
				padding: 0 $padding-rythm-sm;
			}
		}
	}

	@include to-sm {
		font-size: $fs-micro;

		&__filters {
			.form-boolean-group {
				grid-template-columns: 1fr 1fr;

				.form-boolean-group {
					&__label {
						font-size: $fs-body;
					}
				}
			}
		}
	}
}
