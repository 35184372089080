$menu-icon-bar-width: 2px;
$menu-icon-bar-offset: 2px;
$menu-size: 20px;

.menu-icon {
	&__container {
		width: $menu-size;
		height: $menu-size;
		position: relative;
		transform: rotate(0deg);
		transition: 0.5s ease-in-out;
		span {
			display: block;
			position: absolute;
			height: $menu-icon-bar-width;
			width: 100%;
			background: $c-beige-dark;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: 0.25s ease-in-out;

			&:nth-child(1) {
				top: $menu-icon-bar-offset;
			}
			&:nth-child(2),
			&:nth-child(3) {
				top: 50%;
				margin-top: -#{$menu-icon-bar-width/2};
			}
			&:nth-child(4) {
				top: $menu-size - ($menu-icon-bar-width + $menu-icon-bar-offset);
			}
		}
	}

	&.is-active {
		.menu-icon__container {
			span {
				background: $light-grey;
				&:nth-child(1) {
					top: 50%;
					margin-top: -#{$menu-icon-bar-width/2};
					width: 0%;
					left: 50%;
				}

				&:nth-child(2) {
					transform: rotate(45deg);
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
				}

				&:nth-child(4) {
					top: 50%;
					margin-top: -#{$menu-icon-bar-width/2};
					width: 0%;
					left: 50%;
				}
			}
		}
	}
}
