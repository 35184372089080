.press-accordion {
	.font-bold {
		font-weight: 700;
	}

	.items-center {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.flex {
		display: flex;
	}

	.press-accordion__container {
		border-bottom: 1px solid black;

		.press-accordion__section {
			padding: 0.75rem 0;
			cursor: pointer;

			div {
				float: left;
				font-size: 1.5rem;
			}

			.press-accordion__title {
				width: 95%;
			}

			.press-accordion__chevron {
				width: 5%;
				svg {
					width: 20px;
					transition: all 0.2s;
				}

				.rotate-180 {
					transform: rotate(180deg);
				}
			}
		}

		.press-accordion__content {
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.2s ease-out;
		}
	}
}
