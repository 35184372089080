.brand-pip {
	@include base-heading;

	display: flex;
	align-items: center;
	& svg,
	&__asset {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}
	&__label {
		padding-top: 0.2em; // Ensure the label is v-centered because font is badly typeset
		display: inline-block;
	}
}
