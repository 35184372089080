.post-card {
	background-color: $c-neut-grey-dark;
	padding: $padding-rythm;
	display: flex;
	flex-direction: column;
	height: 100%;
	color: $c-beige-dark;

	&__meta {
		display: flex;
		justify-content: space-between;
		font-style: italic;
	}

	&__title {
		font-family: $heading-font;
		font-size: $fs-body-lg;
		color: $c-beige-middle;
		margin-top: 30px;
	}

	&__subtitle {
		margin-top: 10px;
	}

	&__excerpt {
		color: $c-beige-middle;
		margin: 30px 0;
	}

	&__cta {
		margin-top: auto;
		align-self: flex-end;
		color: $c-beige-middle;
	}
}
