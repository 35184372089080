.l-card-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: $padding-rythm * 2;
	@supports (display: grid) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 10px 10px;
	}

	& > * {
		flex-basis: 48%;
		align-self: end;
	}

	&--compact {
		grid-template-columns: repeat(1, 1fr);

		& > * {
			flex-basis: 33%;
		}
	}

	@include from($bp-tiny) {
		grid-template-columns: repeat(3, 1fr);
	}

	@include from-sm {
		grid-template-columns: repeat(4, 1fr);
		&--compact {
			grid-template-columns: repeat(2, 1fr);
		}
		& > * {
			flex-basis: 24%; // 1/6 + some spaceing
		}
	}

	@include from-md {
		grid-template-columns: repeat(6, 1fr);

		&--compact {
			grid-template-columns: repeat(3, 1fr);
		}
		& > * {
			flex-basis: 15%; // 1/6 + some spaceing
		}
	}

	@include from-lg {
		&--compact {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}
