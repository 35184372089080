.l-column-content {
	background-color: $c-beige-light;
	color: $c-purp-grey-middle;
	padding: $padding-rythm-sm;
	box-shadow: $box-shadow-dark;
	display: flex;
	flex-direction: column;
	&__pip {
		margin-bottom: 20px;

		> span {
			padding-top: 0;
		}
	}

	&__image {
		// box-shadow: $box-shadow-light;
	}

	&__title {
		font-size: $fs-title;
		font-weight: normal;
		line-height: 1.1;
	}

	&__subtitle {
		font-size: $fs-subtitle;
		font-family: $heading-font;
		margin: 10px 0;

		& a {
			display: inline-block;
			&:hover {
				text-decoration: underline;
				text-underline-position: unset;
			}
		}
	}

	&__metadata {
		margin: 8px 0;
	}

	&__cta {
		margin-top: $padding-rythm;
		width: 100%;
		display: inline-flex;
	}

	&__body {
		margin-top: $padding-rythm;
	}

	&__aside-cta {
		& + & {
			margin-top: $padding-rythm-sm;
		}
	}

	&__mobile-actions {
		& .btn {
			margin-top: 10px;
		}
	}

	&__action-link {
		@include base-link();
		font-family: $heading-font;
		font-size: $fs-micro;
		margin-top: 1em;
	}

	@include to-md {
		margin-top: $padding-rythm;
		&__main {
			order: 2;
		}

		&__aside {
			order: 1;
			margin-bottom: $padding-rythm;

			&--desktop-only {
				display: none;
			}
		}
		&__metadata {
			margin: 10px 0 $padding-rythm-sm 0;
		}
		&__image,
		&__aside-cta {
			width: 40%;
		}
	}

	@include to-sm {
		&__image,
		&__aside-cta {
			width: 60%;
		}
	}

	@include from-md {
		margin-top: $padding-rythm * 4;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: $padding-rythm * 2;
		flex-direction: row;

		&__title {
			font-size: $fs-title-lg;
		}

		&__main {
			width: calc(((100% / 3) * 2) - ((80px / 3) * 2));
		}

		&__aside {
			width: calc(100% / 3 - (80px / 3));
		}

		&__cta {
			width: 50%;
		}

		&__body {
			margin-top: $padding-rythm * 2;
		}

		&__mobile-actions {
			display: none;
		}
	}
}
